import { CampaignsListLocalStorageKeys } from '@features/campaigns/list/consts/localStorageKeys';
import { FilterName } from '@features/campaigns/shared/components/Filters/consts/filtersName';
import { FiltersType } from '@features/campaigns/shared/types/filters';

type FilterType = FiltersType | 'search';

const useCampaignsListFilter = (filterName: FilterType) => {
  switch (filterName) {
    case FilterName.SEARCH:
      return localStorage.getItem(CampaignsListLocalStorageKeys.CAMPAIGNS_LIST_FILTER_SEARCH);
    case FilterName.FORMAT:
      return localStorage.getItem(CampaignsListLocalStorageKeys.CAMPAIGNS_LIST_FILTER_FORMAT);
    case FilterName.STATUS:
      return localStorage.getItem(CampaignsListLocalStorageKeys.CAMPAIGNS_LIST_FILTER_STATUS);
    case FilterName.START_DATE:
      return localStorage.getItem(CampaignsListLocalStorageKeys.CAMPAIGNS_LIST_FILTER_START_DATE);
    case FilterName.END_DATE:
      return localStorage.getItem(CampaignsListLocalStorageKeys.CAMPAIGNS_LIST_FILTER_END_DATE);
    default:
      return undefined;
  }
};

const setCampaignsListFilter = (filterName: FilterType, filterValue: string) => {
  switch (filterName) {
    case FilterName.SEARCH:
      return localStorage.setItem(CampaignsListLocalStorageKeys.CAMPAIGNS_LIST_FILTER_SEARCH, filterValue);
    case FilterName.FORMAT:
      return localStorage.setItem(CampaignsListLocalStorageKeys.CAMPAIGNS_LIST_FILTER_FORMAT, filterValue);
    case FilterName.STATUS:
      return localStorage.setItem(CampaignsListLocalStorageKeys.CAMPAIGNS_LIST_FILTER_STATUS, filterValue);
    case FilterName.START_DATE:
      return localStorage.setItem(CampaignsListLocalStorageKeys.CAMPAIGNS_LIST_FILTER_START_DATE, filterValue);
    case FilterName.END_DATE:
      return localStorage.setItem(CampaignsListLocalStorageKeys.CAMPAIGNS_LIST_FILTER_END_DATE, filterValue);
    default:
      return undefined;
  }
};

const removeCampaignsListFilter = (filterName: FilterType) => {
  switch (filterName) {
    case FilterName.SEARCH:
      return localStorage.removeItem(CampaignsListLocalStorageKeys.CAMPAIGNS_LIST_FILTER_SEARCH);
    case FilterName.FORMAT:
      return localStorage.removeItem(CampaignsListLocalStorageKeys.CAMPAIGNS_LIST_FILTER_FORMAT);
    case FilterName.STATUS:
      return localStorage.removeItem(CampaignsListLocalStorageKeys.CAMPAIGNS_LIST_FILTER_STATUS);
    case FilterName.START_DATE:
      return localStorage.removeItem(CampaignsListLocalStorageKeys.CAMPAIGNS_LIST_FILTER_START_DATE);
    case FilterName.END_DATE:
      return localStorage.removeItem(CampaignsListLocalStorageKeys.CAMPAIGNS_LIST_FILTER_END_DATE);
    default:
      return undefined;
  }
};

export { useCampaignsListFilter, setCampaignsListFilter, removeCampaignsListFilter };
