import { createContext, PropsWithChildren } from 'react';

// TODO - ESLint rule temporary disabled because is not used anywhere in the code. We do not have any FF for now.
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const FeatureFlagPossibleState = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
} as const;

type FeatureFlagState = keyof typeof FeatureFlagPossibleState;

// Example usage:
/*
const featureFlagsMap = {
  FeatureFlag_Reporting: import.meta.env.VITE_FF_REPORTING,
} as const;
*/

const featureFlagsMap = {} as const;

type FeatureFlagKey = keyof typeof featureFlagsMap;

export type FeatureFlags = Record<FeatureFlagKey, FeatureFlagState | undefined>;

export const FeatureFlagsContext = createContext<FeatureFlags | undefined>(undefined);

export function FeatureFlagsProvider({ children }: PropsWithChildren) {
  return <FeatureFlagsContext.Provider value={featureFlagsMap}>{children}</FeatureFlagsContext.Provider>;
}
