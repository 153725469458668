import { Badge } from 'primereact/badge';
import { useReducer } from 'react';
import { useTranslation } from 'react-i18next';

import ExternalUsersListTable from '@features/users/list/components/Table/ExternalUsersListTable';
import { initialState, reducer } from '@features/users/list/reducers/externalUsersListReducer';
import { Reducer } from '@features/users/list/types/externalUsersListReducer';
import stylesPageHeader from '@shared/components/PageHeader/PageHeader.module.scss';
import PageHeader from '@shared/components/PageHeader/PageHeader.tsx';
import commonTagStyles from '@shared/components/Tag/Tag.module.scss';
import { i18nNameSpace } from '@shared/consts/i18n';
import commonListStyles from '@shared/containers/ListPage/ListPage.module.scss';

function UsersListPage() {
  const { t } = useTranslation(i18nNameSpace.USERS);
  const [state, dispatch] = useReducer<Reducer>(reducer, initialState);

  // const handleOnSearchChange = debounce((value?: string) => {
  //   dispatch({ type: actions.CHANGE_SEARCH_QUERY, payload: { search: value } });
  // }, 500);

  return (
    <div className={commonListStyles.wrapper}>
      <PageHeader>
        <div className={commonListStyles.headerContentContainer}>
          <div className={commonListStyles.pageTitleContainer}>
            <h2 className={stylesPageHeader.pageTitle}>{t('page.title')}</h2>
            <Badge className={commonTagStyles.counter} value={state.usersCount || 0} />
          </div>
        </div>
      </PageHeader>

      <section className={commonListStyles.contentWrapper}>
        {/* Feature temporary removed from UI - PBLTL-930 */}
        {/*<Search onChangeHandler={handleOnSearchChange} placeholder={t('page.search')} width={'22.86rem'} />*/}
        <ExternalUsersListTable state={state} dispatch={dispatch} />
      </section>
    </div>
  );
}

export default UsersListPage;
