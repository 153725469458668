import { BillingDetailsData } from '@features/campaigns/details/containers/BillingDetails/BillingDetails.dto.ts';
import { ReviewData, reviewDataDTO } from '@features/campaigns/shared/containers/Review/Review.dto';
import {
  CampaignDetails,
  CampaignFormat,
  CampaignFormats,
  CampaignPricing,
  CampaignStatus,
  CampaignType,
} from '@features/campaigns/shared/types/campaign';

export type AvailabilityAndCostData = Partial<Omit<CampaignPricing, 'individualImpressions'>> & { budget: number };

export interface DetailsAndStatusData {
  campaignStatus: CampaignStatus;
  creator: string;
}

export interface CampaignDetailsViewData extends ReviewData {
  startDate: string;
  endDate: string;
  sequentialId: number;
  billingSequentialId: number | null;
  type: CampaignType;
  formatType: CampaignFormat[];
  availabilityAndCost?: AvailabilityAndCostData;
  detailsAndStatus: DetailsAndStatusData;
  billingDetails: BillingDetailsData;
}

const getFormatType = (data: CampaignDetails): CampaignFormat[] => {
  const formatType: CampaignFormat[] = [];

  if (data.adProductDisplay !== null) {
    formatType.push(CampaignFormats.DISPLAY);
  }

  if (data.adProductVideo !== null) {
    formatType.push(CampaignFormats.VIDEO);
  }

  return formatType;
};

export function campaignDetailsDTO(data: CampaignDetails): CampaignDetailsViewData {
  return {
    ...reviewDataDTO(data),
    startDate: data.startDate,
    endDate: data.endDate,
    sequentialId: data.sequentialId,
    billingSequentialId: data.billingSequentialId,
    type: data.type,
    formatType: getFormatType(data),
    availabilityAndCost: {
      impressions: data.pricing?.impressions,
      availableImpressions: data.pricing?.availableImpressions,
      estimatedReach: data.pricing?.estimatedReach,
      estimatedFrequency: data.pricing?.estimatedFrequency,
      cpm: data.pricing?.cpm,
      targetSize: data.pricing?.targetSize,
      budget: data.budget,
    },
    detailsAndStatus: {
      campaignStatus: data.status,
      creator: data.creatorName,
    },
    billingDetails: {
      agency: data.agency,
      purchaseOrder: data.purchaseOrder,
      billingContact: data.billingContact,
      authorizedBudget: data.authorizedBudget,
      createdBy: data.createdBy,
      advertiserId: data.advertiser.id,
      validFromDate: data.startDate,
      validToDate: data.endDate,
    },
  };
}
