import cn from 'classnames';
import debounce from 'lodash.debounce';
import { Badge } from 'primereact/badge';
import { useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { AllowAccess } from '@features/auth/ControlAccess/AllowAccess/AllowAccess';
import { MediaTypeTrigger } from '@features/campaigns/list/components/MediaType/Trigger/MediaTypeTrigger.tsx';
import CampaignsListTable from '@features/campaigns/list/components/Table/CampaignsListTable';
import { initialState, reducer } from '@features/campaigns/list/reducers/campaignsListReducer';
import { Actions, Reducer } from '@features/campaigns/list/types/campaignListReducer';
import Filters from '@features/campaigns/shared/components/Filters/Filters';
import { useCampaignsListFilter } from '@features/campaigns/shared/components/Filters/hooks/useFilters';
import { FILTERS } from '@features/campaigns/shared/types/filters';
import stylesPageHeader from '@shared/components/PageHeader/PageHeader.module.scss';
import PageHeader from '@shared/components/PageHeader/PageHeader.tsx';
import { Search } from '@shared/components/Search/Search';
import commonTagStyles from '@shared/components/Tag/Tag.module.scss';
import { i18nNameSpace } from '@shared/consts/i18n';
import { Permissions } from '@shared/consts/permissions';
import commonListStyles from '@shared/containers/ListPage/ListPage.module.scss';
import { routePaths } from '@shared/router/routePaths.ts';

import styles from './CampaignListPage.module.scss';

function CampaignsListPage() {
  const { t } = useTranslation(i18nNameSpace.CAMPAIGNS);
  const [state, dispatch] = useReducer<Reducer>(reducer, initialState);
  const searchValuesFromStorage = useCampaignsListFilter('search');

  const handleOnSearchChange = debounce((value?: string) => {
    dispatch({ type: Actions.CHANGE_SEARCH_QUERY, payload: { search: value } });
  }, 500);

  return (
    <div className={commonListStyles.wrapper}>
      <PageHeader>
        <div className={commonListStyles.headerContentContainer}>
          <div className={commonListStyles.pageTitleContainer}>
            <h2 className={stylesPageHeader.pageTitle}>{t('page.title')}</h2>
            <Badge className={commonTagStyles.counter} value={state.campaignsCount} />
          </div>
          <AllowAccess
            requiredPermissions={[
              Permissions.CAMPAIGN_CREATE_ALL_ADVERTISERS,
              Permissions.CAMPAIGN_CREATE_CROSS_AND_CTV_INTERNAL,
            ]}
          >
            <MediaTypeTrigger />
          </AllowAccess>

          <AllowAccess requiredPermissions={[Permissions.CAMPAIGN_CREATE_CTV_SELF_SERVICE]}>
            <Link
              className={cn('p-button', styles.newCampaignButton)}
              to={routePaths.campaignBuilderInitialize.url()}
              data-testid='create-campaign-button'
            >
              <i className={cn('pi pi-plus', styles.newCampaignIcon)}></i>
              <span>{t('page.newCampaign')}</span>
            </Link>
          </AllowAccess>
        </div>
      </PageHeader>

      <section className={commonListStyles.contentWrapper}>
        <Search
          onChangeHandler={handleOnSearchChange}
          placeholder={t('page.search')}
          width={'22.86rem'}
          valueFromStorage={searchValuesFromStorage || ''}
          dataTestId='campaign-list'
        />
        <Filters
          state={state}
          dispatch={dispatch}
          filtersUsed={[FILTERS.STATUS, FILTERS.FORMAT, FILTERS.START_DATE, FILTERS.END_DATE]}
        />
        <CampaignsListTable state={state} dispatch={dispatch} />
      </section>
    </div>
  );
}

export default CampaignsListPage;
