import { ValueOf } from '@shared/types/common';

interface Meta {
  totalItems: number;
  itemCount: number;
  itemsPerPage: number;
  totalPages: number;
  currentPage: number;
}

export interface AdManagerApiResponse<T> {
  items: T;
  meta: Meta;
}

export interface MsGraphApiResponse<T> {
  items: T;
}

export const AccessRights = {
  READ: 'READ',
  EDIT_ALL: 'EDIT_ALL',
  EDIT_BUDGET: 'EDIT_BUDGET',
  EDIT_START_DATE: 'EDIT_START_DATE',
  EDIT_END_DATE: 'EDIT_END_DATE',
  EDIT_BRAND: 'EDIT_BRAND',
  EDIT_TARGETING: 'EDIT_TARGETING',
  DELETE: 'DELETE',
  DUPLICATE: 'DUPLICATE',
  SUBMIT: 'SUBMIT',
  APPROVE: 'APPROVE',
  APPROVE_BUDGET: 'APPROVE_BUDGET',
  EDIT_AGENCY: 'EDIT_AGENCY',
  READ_AGENCY: 'READ_AGENCY',
  EDIT_BILLING_CONTACT: 'EDIT_BILLING_CONTACT',
  READ_BILLING_CONTACT: 'READ_BILLING_CONTACT',
  EDIT_PURCHASE_ORDER: 'EDIT_PURCHASE_ORDER',
  READ_PURCHASE_ORDER: 'READ_PURCHASE_ORDER',
  EDIT_AUTHORIZED_BUDGET: 'EDIT_AUTHORIZED_BUDGET',
  READ_AUTHORIZED_BUDGET: 'READ_AUTHORIZED_BUDGET',
  EXPORT_REPORT: 'EXPORT_REPORT',
} as const;

export type AccessRight = ValueOf<typeof AccessRights>;
