import { t } from 'i18next';

import {
  CampaignDetails,
  CampaignMediaType,
  CampaignStatus,
  CreativeService,
  InvalidSectionsItem,
  Operation,
  OperationType,
} from '@features/campaigns/shared/types/campaign';
import { AccessRight } from '@shared/api/commonApiInterfaces';
import { ICON_VARIANTS, IconVariants } from '@shared/components/Icon/IconMapper';
import { i18nNameSpace } from '@shared/consts/i18n';

export interface HighlightData {
  label: `builder.steps.step05.highlights.${string}`;
  value?: number;
  isMoneyValue: boolean;
  iconVariant: IconVariants;
}

export interface CampaignDetailsData {
  advertiserName: string;
  advertiserCode: string;
  brandName: string;
  brandCode: string;
  startDate: string;
  endDate: string;
  budget: number;
  isAgencyCommissionGross: boolean;
  campaignName: string;
}

export type TargetData =
  | {
      label: `review.labels.${string}`;
      variant?: Operation | null;
      items?: string[];
    }
  | {
      label: 'review.labels.contentPackage';
      variant?: typeof OperationType.INCLUDE;
      items?: string;
    };

export interface AdProductsData {
  formatType: string;
  creativeServices: string;
  numberOfCreatives?: number | string;
  videoProductsIncluded?: string;
  lShapeImageUrl?: string | null;
  landingImageUrl?: string | null;
}

export interface ReviewData {
  highlights: HighlightData[];
  campaignDetails: CampaignDetailsData;
  target: TargetData[];
  adProducts: AdProductsData;
  additionalNote: string;
  invalidSections?: InvalidSectionsItem[];
  campaignStatus: CampaignStatus;
  accessRights: AccessRight[];
  mediaType: CampaignMediaType;
}

interface FormatTypeFunctionParams {
  adProductVideo: CampaignDetails['adProductVideo'];
  adProductDisplay: CampaignDetails['adProductDisplay'];
}

function formatFormatType({ adProductVideo, adProductDisplay }: FormatTypeFunctionParams) {
  const formatTypes: string[] = [];

  if (!adProductVideo && !adProductDisplay) {
    return '-';
  }

  if (adProductVideo) {
    formatTypes.push(t('builder.formFields.formatType.video', { ns: i18nNameSpace.CAMPAIGNS }));
  }

  if (adProductDisplay) {
    formatTypes.push(t('builder.formFields.formatType.display', { ns: i18nNameSpace.CAMPAIGNS }));
  }

  return formatTypes.join(', ');
}

function formatCreativeService(creativeServices?: CreativeService) {
  if (!creativeServices) {
    return '-';
  }

  return t(`builder.formFields.creativeServices.${creativeServices.toLowerCase()}`, { ns: i18nNameSpace.CAMPAIGNS });
}

function formatVideoProductsIncluded(adProductVideo: CampaignDetails['adProductVideo'] | null) {
  const videoProducts: string[] = [];

  if (!adProductVideo) {
    return '-';
  }

  if (adProductVideo?.vod) {
    videoProducts.push(t('builder.formFields.videoProductsIncluded.vod', { ns: i18nNameSpace.CAMPAIGNS }));
  }

  if (adProductVideo?.linearAddressable) {
    videoProducts.push(
      t('builder.formFields.videoProductsIncluded.linearAddressable', { ns: i18nNameSpace.CAMPAIGNS }),
    );
  }

  return videoProducts.join(', ');
}

export function reviewDataDTO(data: CampaignDetails): ReviewData {
  return {
    highlights: [
      {
        label: 'builder.steps.step05.highlights.impressions',
        value: data.pricing?.impressions,
        isMoneyValue: false,
        iconVariant: ICON_VARIANTS.eye,
      },
      {
        label: 'builder.steps.step05.highlights.estimatedReach',
        value: data.pricing?.estimatedReach,
        isMoneyValue: false,
        iconVariant: ICON_VARIANTS.pieChart,
      },
      {
        label: 'builder.steps.step05.highlights.targetSize',
        value: data.pricing?.targetSize,
        isMoneyValue: false,
        iconVariant: ICON_VARIANTS.user,
      },
      {
        label: 'builder.steps.step05.highlights.estimatedFrequency',
        value: data.pricing?.estimatedFrequency,
        isMoneyValue: false,
        iconVariant: ICON_VARIANTS.clock,
      },
      {
        label: 'builder.steps.step05.highlights.cpm',
        value: data.pricing?.cpm,
        isMoneyValue: true,
        iconVariant: ICON_VARIANTS.barChart,
      },
      {
        label: 'builder.steps.step05.highlights.budget',
        value: data.budget,
        isMoneyValue: true,
        iconVariant: ICON_VARIANTS.wallet,
      },
    ],
    campaignDetails: {
      advertiserName: data.advertiser.name,
      advertiserCode: data.advertiser.code,
      brandName: data.brand.name,
      brandCode: data.brand.code,
      startDate: data.startDate,
      endDate: data.endDate,
      budget: data.budget,
      isAgencyCommissionGross: data.isAgencyCommissionGross,
      campaignName: data.name,
    },
    target: [
      {
        label: 'review.labels.interestSegments',
        variant: data.interestSegments?.operationType,
        items: data.interestSegments?.items.map(({ name }) => name),
      },
      {
        label: 'review.labels.geo',
        variant: data.provinces?.operationType,
        items: data.provinces?.items.map(({ name }) => name),
      },
      {
        label: 'review.labels.ageAndGender',
        variant: data.ageGender?.operationType,
        items: data.ageGender?.items.map(({ name }) => name),
      },
      {
        label: 'review.labels.contentPackage',
        variant: data.contentPackage?.name ? OperationType.INCLUDE : undefined,
        items: data.contentPackage?.name,
      },
    ],
    adProducts: {
      formatType: formatFormatType({
        adProductVideo: data.adProductVideo,
        adProductDisplay: data.adProductDisplay,
      }),
      creativeServices: formatCreativeService(data.adProductDisplay?.creativeServices),
      numberOfCreatives: data.adProductDisplay?.numberOfCreatives || '-',
      videoProductsIncluded: formatVideoProductsIncluded(data.adProductVideo),
      lShapeImageUrl: data.adProductDisplay?.lShapeImageUrl,
      landingImageUrl: data.adProductDisplay?.landingImageUrl,
    },
    additionalNote: data.additionalNote,
    invalidSections: data.invalidSections,
    campaignStatus: data.status,
    accessRights: data.accessRights,
    mediaType: data.mediaType,
  };
}
