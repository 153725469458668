import cn from 'classnames';
import { Button } from 'primereact/button';
import { Calendar, CalendarProps } from 'primereact/calendar';
import { MultiSelect } from 'primereact/multiselect';
import { Nullable } from 'primereact/ts-helpers';
import { Dispatch } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';

import {
  CampaignDetailsReducerAction,
  CampaignDetailsReducerState,
} from '@features/campaigns/list/types/campaignDetailsReducer';
import {
  Actions,
  CampaignListReducerAction,
  CampaignListReducerState,
} from '@features/campaigns/list/types/campaignListReducer';
import { getAvailableFormatOptions } from '@features/campaigns/list/utils/getAvailableFormatOptions.ts';
import { getFormatOptions } from '@features/campaigns/list/utils/getFormatOptions';
import { getStatusOptions } from '@features/campaigns/list/utils/getStatusOptions';
import DropdownHeader from '@features/campaigns/shared/components/Filters/DropdownHeader/DropdownHeader';
import { useFiltersURLSearchParams } from '@features/campaigns/shared/components/Filters/hooks/useFiltersURLSearchParams';
import PlaceholderWithBadge from '@features/campaigns/shared/components/Filters/PlaceholderWithBadge/PlaceholderWithBadge';
import useCampaignDetails from '@features/campaigns/shared/hooks/useCampaignDetails';
import {
  allCampaignFormats,
  allCampaignStatuses,
  AvailableAdProducts,
  CampaignFormat,
  CampaignStatus,
} from '@features/campaigns/shared/types/campaign';
import { FILTERS, FiltersType } from '@features/campaigns/shared/types/filters';
import { i18nNameSpace } from '@shared/consts/i18n';

import styles from './Filters.module.scss';

type ReducerState = CampaignListReducerState | CampaignDetailsReducerState;

interface FiltersProps {
  state: ReducerState;
  dispatch: Dispatch<CampaignDetailsReducerAction> | Dispatch<CampaignListReducerAction>;
  filtersUsed: FiltersType[];
  availableFormatFilters?: AvailableAdProducts;
}

function Filters({ state, dispatch, filtersUsed, availableFormatFilters }: FiltersProps) {
  const { t } = useTranslation(i18nNameSpace.CAMPAIGNS);
  const [searchParams, setSearchParams] = useSearchParams();
  const { campaignId } = useParams();

  const { data: campaignDetailsData } = useCampaignDetails({
    campaignId: campaignId!,
    enabled: Boolean(campaignId),
  });

  useFiltersURLSearchParams({ state, searchParams, setSearchParams, dispatch, campaignId });

  const filterMinDate = campaignId && campaignDetailsData ? new Date(campaignDetailsData?.startDate) : undefined;
  const filterMaxDate = campaignId && campaignDetailsData ? new Date(campaignDetailsData?.endDate) : undefined;
  const formatOptions = availableFormatFilters
    ? getAvailableFormatOptions(t, availableFormatFilters)
    : getFormatOptions(t);
  const isSelectAllAvailable = formatOptions.length > 1;
  const areAllFormatsChecked = state.filters?.format && state.filters.format.length === formatOptions.length;
  const isMinimumOneFormatChecked = state.filters?.format && state.filters.format.length > 0;
  const isCheckboxAllFormatIndeterminate = Boolean(isMinimumOneFormatChecked && !areAllFormatsChecked);
  const campaignListState = state as CampaignListReducerState;
  const firstPageState = { first: 0, page: 0 };

  const statusOptions = getStatusOptions(t);

  const areAllStatusesChecked =
    campaignListState.filters?.status && campaignListState.filters.status?.length === statusOptions.length;
  const isMinimumOneStatusChecked = campaignListState.filters?.status && campaignListState.filters.status.length > 0;
  const isCheckboxAllStatusIndeterminate = Boolean(isMinimumOneStatusChecked && !areAllStatusesChecked);

  const handleOnSelectAllFormatCheckboxChange = (shouldCheckAllCheckboxes: boolean) => {
    const updatedFormatState = shouldCheckAllCheckboxes ? allCampaignFormats : [];

    dispatch({
      type: Actions.CHANGE_FORMAT_FILTER,
      payload: { ...firstPageState, filters: { format: updatedFormatState } },
    });
  };

  const handleOnFormatMultiSelectChange = ({ value }: { value: CampaignFormat[] }) => {
    dispatch({ type: Actions.CHANGE_FORMAT_FILTER, payload: { ...firstPageState, filters: { format: value } } });
  };

  const handleOnSelectAllStatusCheckboxClick = (shouldCheckAllCheckboxes: boolean) => {
    const updatedStatusState = shouldCheckAllCheckboxes ? allCampaignStatuses : [];
    const dispatchTyped = dispatch as Dispatch<CampaignListReducerAction>;

    dispatchTyped({
      type: Actions.CHANGE_STATUS_FILTER,
      payload: { ...firstPageState, filters: { status: updatedStatusState } },
    });
  };

  const handleOnStatusMultiSelectChange = ({ value }: { value: CampaignStatus[] }) => {
    const dispatchTyped = dispatch as Dispatch<CampaignListReducerAction>;

    dispatchTyped({
      type: Actions.CHANGE_STATUS_FILTER,
      payload: { ...firstPageState, filters: { status: value } },
    });
  };

  const handleOnStartDateCalendarChange = ({ value }: CalendarProps) => {
    const startDate: Nullable<Date> = Array.isArray(value) ? value[0] : value;

    dispatch({
      type: Actions.CHANGE_START_DATE_FILTER,
      payload: { ...firstPageState, filters: { startDate } },
    });
  };

  const handleOnEndDateCalendarChange = ({ value }: CalendarProps) => {
    const endDate: Nullable<Date> = Array.isArray(value) ? value[0] : value;

    dispatch({ type: Actions.CHANGE_END_DATE_FILTER, payload: { ...firstPageState, filters: { endDate } } });
  };

  const handleOnClearAllButtonClick = () => {
    dispatch({ type: Actions.CLEAR_FILTERS });
  };

  return (
    <div className={cn(styles.filtersWrapper, campaignId && styles.campaignDetailsFilters)}>
      {filtersUsed.includes(FILTERS.FORMAT) && (
        <MultiSelect
          data-testid={'campaign-format-filter'}
          className={cn(styles.filter, styles.multiSelect)}
          options={formatOptions}
          placeholder={t('page.list.columns.format')}
          onChange={handleOnFormatMultiSelectChange}
          value={state.filters?.format}
          maxSelectedLabels={formatOptions.length}
          aria-label='format filter'
          showSelectAll={isSelectAllAvailable}
          selectedItemTemplate={
            isMinimumOneFormatChecked && (
              <PlaceholderWithBadge
                data={state.filters?.format}
                placeholder={t('page.list.columns.format')}
                prefixId='format'
              />
            )
          }
          panelHeaderTemplate={(event) =>
            isSelectAllAvailable && (
              <DropdownHeader
                data-testid={'format-filter-panel-header'}
                panelEvent={event}
                prefixId='format'
                isIndeterminate={isCheckboxAllFormatIndeterminate}
                handleOnCheckboxChange={handleOnSelectAllFormatCheckboxChange}
              />
            )
          }
          panelClassName={styles.panel}
        />
      )}
      {filtersUsed.includes(FILTERS.START_DATE) && (
        <Calendar
          data-testid={'campaign-start-date-filter'}
          className={styles.filter}
          placeholder={t('page.list.columns.startDate')}
          dateFormat='dd/mm/yy'
          value={state.filters?.startDate}
          viewDate={filterMinDate}
          minDate={filterMinDate}
          maxDate={filterMaxDate}
          onChange={handleOnStartDateCalendarChange}
          showButtonBar
          todayButtonClassName={styles.hidden}
          clearButtonClassName={styles.clearButton}
        />
      )}
      {filtersUsed.includes(FILTERS.END_DATE) && (
        <Calendar
          data-testid={'campaign-end-date-filter'}
          className={styles.filter}
          placeholder={t('page.list.columns.endDate')}
          dateFormat='dd/mm/yy'
          value={state.filters?.endDate}
          viewDate={filterMaxDate}
          minDate={filterMinDate}
          maxDate={filterMaxDate}
          onChange={handleOnEndDateCalendarChange}
          showButtonBar
          todayButtonClassName={styles.hidden}
          clearButtonClassName={styles.clearButton}
        />
      )}
      {filtersUsed.includes(FILTERS.STATUS) && (
        <MultiSelect
          data-testid='campaign-status-filter'
          className={cn(styles.filter, styles.multiSelect)}
          options={statusOptions}
          placeholder={t('page.list.columns.status')}
          onChange={handleOnStatusMultiSelectChange}
          value={campaignListState.filters?.status}
          maxSelectedLabels={statusOptions.length}
          aria-label='status filter'
          selectedItemTemplate={
            isMinimumOneStatusChecked && (
              <PlaceholderWithBadge
                data={campaignListState.filters?.status}
                placeholder={t('page.list.columns.status')}
                prefixId='status'
              />
            )
          }
          panelHeaderTemplate={(event) => (
            <DropdownHeader
              data-testid='status-filter-panel-header'
              panelEvent={event}
              prefixId='status'
              isIndeterminate={isCheckboxAllStatusIndeterminate}
              handleOnCheckboxChange={handleOnSelectAllStatusCheckboxClick}
            />
          )}
          panelClassName={styles.panel}
        />
      )}
      <Button
        data-testid={'clear-all-campaign-filters-button'}
        text
        label={t('page.clearAll')}
        onClick={handleOnClearAllButtonClick}
      />
    </div>
  );
}

export default Filters;
