import { ExtendedTreeNodeType } from '@features/campaigns/builder/components/FormFields/common/Tree/types/treeTypes';
import { CONTENT_PACKAGE_NONE_ELEMENT_ID } from '@features/campaigns/builder/consts/common';
import { CreateCampaignFormStep02Values } from '@features/campaigns/builder/containers/FormPages/FormPages.types';
import {
  AgeGender,
  CampaignDetails,
  CampaignMediaType,
  CampaignMediaTypes,
  CampaignStatus,
  InterestSegments,
  InvalidSectionsItem,
  OperationType,
  Provinces,
} from '@features/campaigns/shared/types/campaign';
import { CampaignId } from '@shared/types/campaign';
import { ValueOf } from '@shared/types/common';

export interface UpdateCampaignStep02Payload {
  contentPackage: {
    id: string | null;
  } | null;
  provinces?: {
    operationType: ValueOf<typeof OperationType> | null;
    ids: string[];
  } | null;
  ageGender?: {
    operationType: ValueOf<typeof OperationType> | null;
    ids: string[];
  } | null;
  interestSegments?: {
    operationType: ValueOf<typeof OperationType> | null;
    ids: string[];
  } | null;
}

export interface RestoredStep02Data extends CreateCampaignFormStep02Values {
  id: CampaignId;
  mediaType: CampaignMediaType;
  status: CampaignStatus;
  invalidSections: InvalidSectionsItem[];
}

const getIdsFromTreeNodes = (treeNodes: Provinces | AgeGender | InterestSegments | null): string[] => {
  if (treeNodes && treeNodes.items?.length) {
    const ids = treeNodes.items.map((treeNode) => {
      return treeNode.id;
    });
    return ids;
  } else {
    const parsedTreeNodes = treeNodes as unknown as ExtendedTreeNodeType[];
    const ids = parsedTreeNodes?.map((treeNode) => {
      return treeNode.id;
    });
    return ids;
  }
};

export function createCampaignStep02DTO(
  formValues: CreateCampaignFormStep02Values,
  mediaType: CampaignMediaType,
): UpdateCampaignStep02Payload {
  const {
    contentPackage,
    provinces,
    provincesOperationType,
    ageGender,
    ageAndGenderOperationType,
    interestSegments,
    interestSegmentsOperationType,
  } = formValues;

  if (mediaType === CampaignMediaTypes.CROSS && contentPackage) {
    const payload: UpdateCampaignStep02Payload = {
      contentPackage: { id: contentPackage.id },
    };

    return payload;
  }

  const contentPackageValue = contentPackage?.id === CONTENT_PACKAGE_NONE_ELEMENT_ID ? null : contentPackage?.id;
  const provincesValue = getIdsFromTreeNodes(provinces)?.length > 0 ? getIdsFromTreeNodes(provinces) : null;
  const ageAndGenderValue = getIdsFromTreeNodes(ageGender)?.length > 0 ? getIdsFromTreeNodes(ageGender) : null;
  const interestSegmentsValue =
    getIdsFromTreeNodes(interestSegments)?.length > 0 ? getIdsFromTreeNodes(interestSegments) : null;

  const payload: UpdateCampaignStep02Payload = {
    contentPackage: null,
    provinces: null,
    ageGender: null,
    interestSegments: null,
  };

  if (contentPackageValue && contentPackageValue !== null) {
    payload.contentPackage = {
      id: contentPackageValue,
    };
  }

  if (provincesValue !== null) {
    payload.provinces = {
      operationType: provincesOperationType,
      ids: provincesValue,
    };
  }

  if (ageAndGenderValue !== null) {
    payload.ageGender = {
      operationType: ageAndGenderOperationType,
      ids: ageAndGenderValue,
    };
  }

  if (interestSegmentsValue !== null) {
    payload.interestSegments = {
      operationType: interestSegmentsOperationType,
      ids: interestSegmentsValue,
    };
  }

  return payload;
}

export function restoreStep02DataDTO(data: CampaignDetails | RestoredStep02Data): RestoredStep02Data {
  return {
    id: data.id,
    contentPackageOperationType: OperationType.INCLUDE,
    contentPackage: data.contentPackage,
    ageAndGenderOperationType: data.ageGender ? data.ageGender.operationType : null,
    ageGender: data.ageGender,
    interestSegments: data.interestSegments,
    interestSegmentsOperationType: data.interestSegments ? data.interestSegments.operationType : null,
    provinces: data.provinces,
    provincesOperationType: data.provinces ? data.provinces.operationType : null,
    mediaType: data.mediaType,
    status: data.status,
    invalidSections: data.invalidSections,
  };
}
