import barChartIcon from '@shared/assets/icons/bar-chart.png';
import barChartIcon2x from '@shared/assets/icons/bar-chart@2x.png';
import clockIcon from '@shared/assets/icons/clock.png';
import clockIcon2x from '@shared/assets/icons/clock@2x.png';
import closeGray from '@shared/assets/icons/close-gray.png';
import closeGray2x from '@shared/assets/icons/close-gray@2x.png';
import cross from '@shared/assets/icons/cross.png';
import cross2x from '@shared/assets/icons/cross@2x.png';
import ctv from '@shared/assets/icons/ctv.png';
import ctv2x from '@shared/assets/icons/ctv@2x.png';
import dangerIcon from '@shared/assets/icons/danger.png';
import dangerIcon2x from '@shared/assets/icons/danger@2x.png';
import eveIcon from '@shared/assets/icons/eye.png';
import eyeIcon2x from '@shared/assets/icons/eye@2x.png';
import filterGray from '@shared/assets/icons/filter-gray.png';
import filterGray2x from '@shared/assets/icons/filter-gray@2x.png';
import galleryIcon from '@shared/assets/icons/gallery.png';
import galleryIcon2x from '@shared/assets/icons/gallery@2x.png';
import gameIcon from '@shared/assets/icons/game.png';
import gameIcon2x from '@shared/assets/icons/game@2x.png';
import locationIcon from '@shared/assets/icons/location.png';
import locationIcon2x from '@shared/assets/icons/location@2x.png';
import phone from '@shared/assets/icons/phone.png';
import phone2x from '@shared/assets/icons/phone@2x.png';
import pieChartIcon from '@shared/assets/icons/pie-chart.png';
import pieChartIcon2x from '@shared/assets/icons/pie-chart@2x.png';
import starIcon from '@shared/assets/icons/star.png';
import starIcon2x from '@shared/assets/icons/star@2x.png';
import userIcon from '@shared/assets/icons/user.png';
import userIcon2x from '@shared/assets/icons/user@2x.png';
import videoIcon from '@shared/assets/icons/video.png';
import videoIcon2x from '@shared/assets/icons/video@2x.png';
import walletIcon from '@shared/assets/icons/wallet.png';
import walletIcon2x from '@shared/assets/icons/wallet@2x.png';

export type IconVariants =
  | 'barChart'
  | 'danger'
  | 'eye'
  | 'clock'
  | 'gallery'
  | 'game'
  | 'location'
  | 'pieChart'
  | 'star'
  | 'user'
  | 'video'
  | 'wallet'
  | 'filterGray'
  | 'closeGray'
  | 'cross'
  | 'ctv'
  | 'phone';

export const ICON_VARIANTS: Record<IconVariants, IconVariants> = {
  barChart: 'barChart',
  danger: 'danger',
  eye: 'eye',
  clock: 'clock',
  gallery: 'gallery',
  game: 'game',
  location: 'location',
  pieChart: 'pieChart',
  star: 'star',
  user: 'user',
  video: 'video',
  wallet: 'wallet',
  filterGray: 'filterGray',
  closeGray: 'closeGray',
  cross: 'cross',
  ctv: 'ctv',
  phone: 'phone',
};

export const IconMap: Record<IconVariants, [string, string]> = {
  barChart: [barChartIcon, barChartIcon2x],
  danger: [dangerIcon, dangerIcon2x],
  eye: [eveIcon, eyeIcon2x],
  clock: [clockIcon, clockIcon2x],
  gallery: [galleryIcon, galleryIcon2x],
  game: [gameIcon, gameIcon2x],
  location: [locationIcon, locationIcon2x],
  pieChart: [pieChartIcon, pieChartIcon2x],
  star: [starIcon, starIcon2x],
  user: [userIcon, userIcon2x],
  video: [videoIcon, videoIcon2x],
  wallet: [walletIcon, walletIcon2x],
  filterGray: [filterGray, filterGray2x],
  closeGray: [closeGray, closeGray2x],
  cross: [cross, cross2x],
  ctv: [ctv, ctv2x],
  phone: [phone, phone2x],
};
