import './setup-sentry';

import { EventType } from '@azure/msal-browser';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';

import { worker } from '@shared/mocks/browser.ts';
import AppProviders from '@shared/providers/AppProviders.tsx';
import { msalInstance } from '@shared/providers/AzureAuthProvider/authConfig.ts';

import '../i18n.config.ts';

await msalInstance.initialize().then(() => {
  // Default to using the first account if no account is active on page load
  if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
  }

  // Optional - This will update account state if a user signs in from another tab or window
  msalInstance.enableAccountStorageEvents();

  msalInstance.addEventCallback((event) => {
    if (
      event.eventType === EventType.LOGIN_SUCCESS ||
      event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
      event.eventType === EventType.SSO_SILENT_SUCCESS
    ) {
      const account =
        event.payload && 'account' in event.payload && event.payload.account ? event.payload.account : null;
      msalInstance.setActiveAccount(account);
    }
  });

  if (import.meta.env.VITE_APP_RUN_WORKER === 'true') {
    void worker.start({
      onUnhandledRequest: 'bypass',
    });
  }

  return createRoot(document.getElementById('root')!).render(
    <StrictMode>
      <AppProviders />
    </StrictMode>,
  );
});
