import cn from 'classnames';
import { Tooltip } from 'primereact/tooltip';
import { PropsWithChildren } from 'react';

import styles from './ItemRow.module.scss';

interface ItemRowProps {
  labelText: string;
  iconClass?: string;
  tooltipText?: string;
  customIcon?: JSX.Element;
}

export function ItemRow({ labelText, tooltipText, iconClass, customIcon, children }: PropsWithChildren<ItemRowProps>) {
  return (
    <div className={styles.wrapper}>
      <Tooltip target={'.tooltip-icon'} />

      <p className={styles.labelWrapper}>
        {iconClass && <i className={cn('pi', iconClass, styles.labelIcon)}></i>}
        {customIcon && customIcon}
        <span className={styles.labelText}>{labelText}</span>
        {tooltipText && (
          <i
            className={cn('pi pi-info-circle tooltip-icon', styles.labelTooltip)}
            data-pr-tooltip={tooltipText}
            data-pr-position='top'
            data-pr-at='center top-10'
          ></i>
        )}
      </p>

      <div className={styles.valueWrapper}>{children}</div>
    </div>
  );
}
