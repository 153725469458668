import { useQuery } from '@tanstack/react-query';
import { createContext, PropsWithChildren } from 'react';
import { useLocation } from 'react-router-dom';

import { QueryKeys } from '@features/auth/consts/queryKeys';
import { getRolesDetails } from '@features/auth/services/rolesDetails';
import { routePaths } from '@shared/router/routePaths';
import { RoleDetails } from '@shared/types/role';

export const RolesDetailsContext = createContext<RoleDetails[] | undefined>(undefined);

export function RolesDetailsProvider({ children }: PropsWithChildren) {
  const { pathname } = useLocation();
  const isNotServerErrorPage = pathname !== routePaths.serverDown;

  const { data } = useQuery({
    queryKey: [QueryKeys.ROLES_DETAILS],
    queryFn: () => getRolesDetails(),
    enabled: isNotServerErrorPage,
  });

  const value: RoleDetails[] | undefined = data?.definitions;

  return <RolesDetailsContext.Provider value={value}>{children}</RolesDetailsContext.Provider>;
}
