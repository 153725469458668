import debounce from 'lodash.debounce';
import { InputNumber, InputNumberChangeEvent } from 'primereact/inputnumber';
import { Controller, ControllerRenderProps, FieldValues, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FieldErrorMessage } from '@features/campaigns/builder/components/FormFields/common/FieldErrorMessage/FieldErrorMessage';
import { FieldTypes } from '@features/campaigns/builder/components/FormFields/FieldTypes.ts';
import styles from '@features/campaigns/shared/components/FormFields/FormFields.module.scss';
import { i18nNameSpace } from '@shared/consts/i18n.ts';

interface FieldBudgetProps {
  labelVisible?: boolean;
  onChangeCallback?: () => void;
  disabled: boolean;
  debounced?: boolean;
}
export function FieldBudget({
  labelVisible = true,
  onChangeCallback,
  disabled = false,
  debounced = false,
}: FieldBudgetProps) {
  const { t } = useTranslation(i18nNameSpace.CAMPAIGNS);
  const { control } = useFormContext();

  const sourceOnChangeHandler = (
    event: InputNumberChangeEvent,
    field: ControllerRenderProps<FieldValues, typeof FieldTypes.BUDGET>,
  ) => {
    field.onChange(Number(event.value));

    if (onChangeCallback) {
      onChangeCallback();
    }
  };
  const debouncedOnChangeHandler = debounce(sourceOnChangeHandler, 1000);

  const onChangeHandler = (
    event: InputNumberChangeEvent,
    field: ControllerRenderProps<FieldValues, typeof FieldTypes.BUDGET>,
  ) => (debounced ? debouncedOnChangeHandler(event, field) : sourceOnChangeHandler(event, field));

  return (
    <div className={styles.fieldWrapper}>
      {labelVisible && (
        <label htmlFor={FieldTypes.BUDGET} className={styles.fieldLabel}>
          {t('builder.formFields.budget.label')}
        </label>
      )}

      <Controller
        name={FieldTypes.BUDGET}
        control={control}
        render={({ field, formState: { errors } }) => {
          return (
            <>
              <span className='p-input-icon-left'>
                <i className='pi pi-euro' />
                <InputNumber
                  id={FieldTypes.BUDGET}
                  {...field}
                  onChange={(e) => onChangeHandler(e, field)}
                  name='budget'
                  disabled={disabled}
                  maxLength={7}
                  placeholder={t('builder.formFields.budget.placeholder')}
                  data-testid='field-budget'
                  min={1}
                />
              </span>
              {errors?.[FieldTypes.BUDGET] && (
                <FieldErrorMessage fieldErrorData={errors} fieldName={FieldTypes.BUDGET} />
              )}
            </>
          );
        }}
      />
    </div>
  );
}
