import { DropdownItem } from '@features/campaigns/builder/components/FormFields/FieldTypes.ts';
import { DefaultQueryParams, Item, mapResponseToFieldData } from '@features/campaigns/builder/services/formFields.ts';
import { apiRoutes } from '@features/campaigns/details/consts/apiRoutes.ts';
import { apiClient } from '@shared/api/ApiClient.ts';
import { AdManagerApiResponse } from '@shared/api/commonApiInterfaces.ts';

const PER_PAGE_LIMIT = 30;

interface BillingContactsRequiredParams {
  advertiserId: string;
  validFromDate: string;
  validToDate: string;
}

const defaultPageParam: DefaultQueryParams = {
  page: 1,
  search: '',
};

interface BillingContactsRequestParams {
  reqParam: BillingContactsRequiredParams;
  pageParam: DefaultQueryParams;
}

export async function fetchAgencies({
  pageParam = defaultPageParam,
}): Promise<AdManagerApiResponse<DropdownItem[]> | null> {
  const { page, search } = pageParam;

  return await apiClient
    .get<AdManagerApiResponse<Item[]>>(apiRoutes.getAgencies(), {
      limit: PER_PAGE_LIMIT,
      page,
      search,
    })
    .then((data) => mapResponseToFieldData(data));
}

export async function fetchBillingContacts({
  reqParam,
  pageParam = defaultPageParam,
}: BillingContactsRequestParams): Promise<AdManagerApiResponse<DropdownItem[]> | null> {
  const { page, search } = pageParam;
  const { advertiserId, validFromDate, validToDate } = reqParam;

  return await apiClient
    .get<AdManagerApiResponse<Item[]>>(apiRoutes.getBillingContacts(advertiserId), {
      limit: PER_PAGE_LIMIT,
      page,
      search,
      validFromDate,
      validToDate,
    })
    .then((data) => mapResponseToFieldData(data));
}
