import { createContext, PropsWithChildren, useEffect, useState } from 'react';

import { useAuth, EXTERNAL_USER_ROLE_ID } from '@features/auth/hooks/useAuth';
import { USER_ROLES } from '@features/auth/utils/constants.ts';
import { useRolesDetailsContext } from '@shared/providers/RolesDetailsProvider/useRolesDetailsContext.tsx';
import { UserId } from '@shared/types/users.ts';

type PossibleUserRoles = keyof typeof USER_ROLES;

export interface UserDetails {
  role: PossibleUserRoles;
  id: UserId;
  isExternalUser: boolean;
  isPortfolioSalesUser: boolean;
}

export const UserDetailsContext = createContext<UserDetails | undefined>(undefined);

export function UserDetailsProvider({ children }: PropsWithChildren) {
  const [userDetailsState, setUserDetailsState] = useState<UserDetails | undefined>(undefined);
  const { getRoleId, getUserId } = useAuth();
  const rolesDetailsContext = useRolesDetailsContext();

  useEffect(() => {
    (async () => {
      const userRoleId = await getRoleId();
      const userId = await getUserId();

      if (!userId || !userRoleId) {
        return;
      }

      const isExternalUser = userRoleId === EXTERNAL_USER_ROLE_ID;

      const currentUserRoleData = rolesDetailsContext?.find(({ name: roleName, roleGroupId }) =>
        isExternalUser ? roleName === USER_ROLES.EXTERNAL_SALES_USER : roleGroupId === userRoleId,
      );

      if (!currentUserRoleData) {
        return;
      }

      const isPortfolioSalesUser = currentUserRoleData.name === USER_ROLES.SALES_PORTFOLIO_USER;

      setUserDetailsState((prevState) => ({
        ...prevState,
        role: currentUserRoleData.name,
        id: userId,
        isExternalUser,
        isPortfolioSalesUser,
      }));
    })();
  }, [rolesDetailsContext]);

  return <UserDetailsContext.Provider value={userDetailsState}>{children}</UserDetailsContext.Provider>;
}
