import { CampaignPricing } from '@features/campaigns/shared/types/campaign';

export const mockedPricing: CampaignPricing = {
  impressions: 111,
  availableImpressions: 222,
  estimatedReach: 333,
  estimatedFrequency: 444,
  cpm: 555,
  budget: 666,
  targetSize: 777,
};
