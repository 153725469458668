import cn from 'classnames';

import { HighlightDataPerformance } from '@features/campaigns/performance/containers/Performance/utils/generateKeyMetricsData';
import { Highlight } from '@features/campaigns/shared/components/Review/Highlight/Highlight';
import { HighlightData } from '@features/campaigns/shared/containers/Review/Review.dto';

import styles from './Highlights.module.scss';

interface HighlightsProps {
  data: HighlightData[] | HighlightDataPerformance[];
  className?: string;
}

export function Highlights({ data, className }: HighlightsProps) {
  return (
    <div className={cn(styles.grid, className)} data-testid='highlights-section'>
      {data.map((item) => (
        <Highlight key={item.label} {...item} />
      ))}
    </div>
  );
}
