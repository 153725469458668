import { AccountInfo, InteractionRequiredAuthError } from '@azure/msal-common';
import { useMsal } from '@azure/msal-react';
import { jwtDecode } from 'jwt-decode';
import { useRef } from 'react';

import { authRequest } from '@shared/providers/AzureAuthProvider/authConfig.ts';

export interface UseAuth {
  getPersonalInfo: () => Promise<string | null>;
  getRoleId: () => Promise<string | null>;
  logout: () => void;
  getUserId: () => Promise<string | null>;
}

export const EXTERNAL_USER_ROLE_ID = 'externalUserRoleId';

export function useAuth(): UseAuth {
  const storedAccount = useRef<AccountInfo>();
  const { instance, accounts } = useMsal();

  const accountsFound = accounts && accounts.length > 0;

  if (accountsFound) {
    storedAccount.current = accounts[0];
  }

  const getPersonalInfo = async () => {
    const accessTokenRequest = { ...authRequest, account: accounts[0] };
    try {
      const {
        account: { name },
      } = await instance.acquireTokenSilent(accessTokenRequest);
      return name || '';
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        await instance.acquireTokenRedirect(accessTokenRequest).catch((error) => console.error(error));
      }
    }
    return null;
  };

  const getUserId = async (): Promise<string | null> => {
    const accessTokenRequest = { ...authRequest, account: accounts[0] };
    try {
      const { idTokenClaims } = await instance.acquireTokenSilent(accessTokenRequest);

      if (!idTokenClaims) {
        return null;
      }

      return 'oid' in idTokenClaims && typeof idTokenClaims.oid === 'string' ? idTokenClaims.oid : null;
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        await instance.acquireTokenRedirect(accessTokenRequest).catch((error) => console.error(error));
      }
    }

    return null;
  };

  const getRoleId = async (): Promise<string | null> => {
    const accessTokenRequest = { ...authRequest, account: accounts[0] };
    try {
      const { idTokenClaims } = await instance.acquireTokenSilent(accessTokenRequest);

      if (!idTokenClaims) {
        return null;
      }

      if ('idp_id_token' in idTokenClaims && typeof idTokenClaims.idp_id_token === 'string') {
        const decodedIdpIdToken = jwtDecode(idTokenClaims.idp_id_token);

        const roleId =
          'groups' in decodedIdpIdToken && Array.isArray(decodedIdpIdToken.groups) ? decodedIdpIdToken.groups[0] : null;

        return roleId;
      }

      return EXTERNAL_USER_ROLE_ID;
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        await instance.acquireTokenRedirect(accessTokenRequest).catch((error) => console.error(error));
      }
    }

    return null;
  };

  const logout = async () => {
    await instance.logoutRedirect().catch((error) => console.error(error));
  };

  return { logout, getRoleId, getPersonalInfo, getUserId };
}
