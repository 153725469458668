import { ExternalUser } from '@features/users/list/types/externalUser.ts';
import { MsGraphApiResponse } from '@shared/api/commonApiInterfaces.ts';

export const mockedExternalUsersList: MsGraphApiResponse<ExternalUser[]> = {
  items: [
    {
      givenName: 'User01',
      id: 'b25c5113-f236-4b32-b719-7a25685b84b0',
      mail: 'external01@admanager-user.it',
      role: 'EXTERNAL_SALES_USER',
      surname: 'ImAmExternal',
    },
    {
      givenName: 'User02',
      id: '0c82ed85-b358-4602-92dc-c9b3b32f150f',
      mail: 'external02@admanager-user.it',
      role: 'EXTERNAL_SALES_USER',
      surname: 'ImAmExternal',
    },
    {
      givenName: 'User03',
      id: 'b06d8abc-5f99-4314-b298-cda9f4d58c37',
      mail: 'external03@admanager-user.it',
      role: 'EXTERNAL_SALES_USER',
      surname: 'ImAmExternal',
    },
  ],
};
