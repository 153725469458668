import cn from 'classnames';
import { Checkbox, CheckboxChangeEvent } from 'primereact/checkbox';
import { MultiSelectPanelHeaderTemplateEvent } from 'primereact/multiselect';
import { useTranslation } from 'react-i18next';

import { i18nNameSpace } from '@shared/consts/i18n';

import styles from './DropdownHeader.module.scss';

interface PanelHeaderProps {
  panelEvent: MultiSelectPanelHeaderTemplateEvent;
  prefixId: string;
  isIndeterminate: boolean;
  handleOnCheckboxChange: (shouldCheckAllCheckboxes: boolean) => void;
}

function DropdownHeader({ panelEvent, prefixId, isIndeterminate, handleOnCheckboxChange }: PanelHeaderProps) {
  const { t } = useTranslation(i18nNameSpace.CAMPAIGNS);

  const handleOnChange = (event: CheckboxChangeEvent) => {
    handleOnCheckboxChange(Boolean(event.checked));
  };

  return (
    <div className={cn(styles.panelHeader)}>
      <Checkbox
        inputId={`${prefixId}-checkbox-all`}
        className={cn(styles.checkboxAll, {
          [styles.indeterminate]: isIndeterminate,
        })}
        checked={panelEvent.checked}
        onChange={handleOnChange}
        data-testid={`${prefixId}-checkbox-all`}
      />
      <label
        htmlFor={`${prefixId}-checkbox-all`}
        className={styles.checkboxLabel}
        data-testid={`${prefixId}-checkbox-all-label`}
      >
        {t('page.all')}
      </label>
    </div>
  );
}

export default DropdownHeader;
